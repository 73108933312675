<template>
  <div>
    <!-- 图片容器 -->
    <div v-show="!isVideoPlaying" class="img" @click="toggleVideo">
      <video :src="video" loop autoplay muted></video>
      <!-- <div class="ico">
        <img src="@/assets/ico/三角形 1.png" alt="" />
      </div> -->
    </div>
    <!-- 视频容器 -->
    <div class="videoCon" v-if="isVideoPlaying">
      <div class="close" @click="close">
        <i class="el-icon-close"></i>
      </div>
      <video ref="video" controls muted>
        <source :src="video" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <!-- <el-buttom @click="click"> 点我 </el-buttom> -->
    <!-- <div class="videocon">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      ></video-player>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    // img: {
    //   type: String,
    //   default: ''
    // },
    video: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isVideoPlaying: false, // 控制视频的显示与否
      scrollY: 0 //滚动条位置
    }
  },
  // computed: {
  //   backgroundStyle() {
  //     return {
  //       backgroundImage: `url(${this.img})`,
  //       backgroundSize: 'cover', // 根据需要调整
  //       backgroundRepeat: 'no-repeat' // 根据需要调整
  //       // 其他样式
  //     }
  //   }
  // },
  methods: {
    toggleVideo() {
      document.body.style.overflow = 'hidden'
      this.scrollY =
        document.body.scrollTop || document.documentElement.scrollTop
      this.isVideoPlaying = true
      this.$nextTick(() => {
        this.$refs.video.play() // 播放视频
      })
    },
    close() {
      document.body.style.overflow = ''
      this.$nextTick(() => {
        this.$refs.video.pause() // 暂停视频
        this.isVideoPlaying = false
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollY)
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.img {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1080px;
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: 50% 60%;
  }
  .ico {
    z-index: 2;
    position: absolute;
    width: 150px;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  // background: url(../../../assets/官网首页配图/第八页动图.gif) no-repeat center;
  // background-size: cover;
}
.videoCon {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  video {
    width: 1280px;
    height: 720px;
  }
  .close {
    font-size: 48px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    background: #595a5a;
    height: 80px;
    width: 80px;
    top: 50px;
    right: 100px;
  }
}
</style>
