<template>
  <div class="ACE1Pro">
    <div class="banner">
      <div class="title">ACE 1 Pro</div>
      <div class="osDMS w">
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 12ET7产品介绍.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 1@4x2.png" alt="" />
        </div>
      </div>
      <div></div>
    </div>
    <!-- <div class="video">
      <video
        src="../../../assets/ACE 1 Pro页面配图/Compax_Ace_1_Pro_Video.mp4"
        autoplay
        loop
        muted
      ></video>
    </div> -->
    <ImgVideo :video="video"></ImgVideo>
    <div class="display">
      <div class="w">
        <div class="top">
          <div class="title">Single and double , more combinations</div>
          <div class="text">Wide angle adjustable screen</div>
        </div>
        <div class="img">
          <img src="../../../assets/ACE 1 Pro页面配图/第二页配图.png" alt="" />
        </div>
        <div class="left">
          <div class="title">Main Display</div>
          <div class="text">15.6” 1920 x 1080</div>
          <div class="text">Multi-touch capacitive screen</div>
        </div>
        <div class="right">
          <div class="title">Secondary Display</div>
          <div class="text">10.1” 1280 x 800</div>
        </div>
      </div>
    </div>
    <div class="anodisedAuminium">
      <div class="w">
        <div class="title">Anodised Aluminium</div>
        <div class="img">
          <img src="../../../assets/ACE 1 Pro页面配图/第三页配图.png" alt="" />
        </div>
      </div>
    </div>
    <div class="High-speed">
      <div class="w">
        <div class="title">High-speed Printer</div>
        <div class="img">
          <img
            src="../../../assets/ACE 1 Pro页面配图/第四页配图动图.gif"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="product">
      <div class="line">
        <img src="../../../assets/ACE 1 Pro页面配图/分组 4.png" alt="" />
      </div>
      <div class="topLeft">
        <div class="text">10-point capacitive touch screen</div>
      </div>
      <div class="topRight"><div class="text">7H hardness glass</div></div>
      <div class="rightCon">
        <div class="text">Fully laminated process</div>
      </div>
      <div class="bottomRight">
        <div class="text">80mm thermal printer with</div>
        <div class="text">Socket communication for agile</div>
        <div class="text">and rapid development</div>
      </div>
      <div class="bottomLeft">
        <div class="text">Aluminum alloy</div>
        <div class="text">structurally stable and lightweight</div>
      </div>
    </div>
    <div class="ACE1ProCon">
      <div class="w">
        <div class="titleBox">
          <div class="title">ACE 1 Pro</div>
        </div>
        <div class="itemBox">
          <div class="one">
            <div class="itemA">
              <div class="title">OS</div>
              <div class="text">Android 11</div>
            </div>
            <div class="itemA">
              <div class="title">Memory</div>
              <div class="text">4G DDR4+32G eMCC</div>
            </div>
            <div class="itemB">
              <div class="title">CPU</div>
              <div class="text">RK3568, Qual-core, Cortex A55, 2.0 GHz Max</div>
            </div>
            <div class="itemA">
              <div class="title">Printer</div>
              <div class="text">80mm High-speed printers</div>
            </div>
          </div>
          <div class="one">
            <div class="itemA">
              <div class="title">Speaker</div>
              <div class="text">5W Stereo</div>
            </div>
            <div class="itemA">
              <div class="title">Power Adapter</div>
              <div class="text">Input: 24V/2.75A</div>
            </div>
            <div class="itemA">
              <div class="title">WiFi</div>
              <div class="text">2.4G/5G dual-band Wi-Fi</div>
            </div>
            <div class="itemA">
              <div class="title">BT</div>
              <div class="text">BT5.0</div>
            </div>
            <div class="itemA">
              <div class="title">Ethernet</div>
              <div class="text">10M/100M</div>
            </div>
          </div>
          <div class="two">
            <div class="left">
              <div class="itemA">
                <div class="title">Housing</div>
                <div class="text">Anodised Aluminium</div>
              </div>
              <div class="itemA">
                <div class="title">Color</div>
                <div class="text">Space Black</div>
                <div class="text">Space Gray</div>
                <div class="text">Midnight</div>
              </div>
              <div class="itemA">
                <div class="title">Certification</div>
                <div class="text">CCC, CE, FCC</div>
              </div>
              <div class="itemE">
                <div class="leftText">
                  <div class="title">Dimensions (L*W*H)</div>
                  <div class="text">363mm*210mm*375mm</div>
                </div>
                <div class="rightImg">
                  <div class="top">363mm</div>
                  <div class="left">375mm</div>
                  <div class="right">210mm</div>
                  <div class="img">
                    <img
                      src="../../../assets/ACE 1 Pro页面配图/ACE 1 Pro.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="itemC">
                <div class="title">Peripheral Ports</div>
                <div class="text">RJ45*1</div>
                <div class="text">DC-IN*1</div>
                <div class="text">RJ12*1</div>
                <div class="text">RJ11*1</div>
                <div class="text">USB2.0*4</div>
                <div class="text">TF Expansion card*1</div>
                <div class="text">3.5mm Audio output*1</div>
              </div>
              <div class="itemC">
                <div class="title">Environment</div>
                <div class="text">0 ~ 40℃</div>
                <div class="text">Huminity：</div>
                <div class="text">5%~90%RH</div>
                <div class="text">Storage temperature:</div>
                <div class="text">-12 ~ 40℃</div>
                <div class="text">Huminity：</div>
                <div class="text">10%~90%RH</div>
              </div>
            </div>
          </div>
        </div>
        <div class="downloads">
          <div class="box">
            <div class="itemA">
              <div class="title">ACE 1 Pro</div>
              <div class="text">PDF(1.26MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/ACE_1_Pro.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
            <div class="itemB">
              <div class="title">ACE 1 Pro User Manual</div>
              <div class="text">PDF(1.89MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/ACE_1_Pro_User_Manual.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import video from '../../../assets/ACE 1 Pro页面配图/Compax_Ace_1_Pro_Video.mp4'
import ImgVideo from '@/components/imgVideo/index'
export default {
  components: {
    ImgVideo
  },
  data() {
    return {
      video
    }
  }
}
</script>

<style lang="less" scoped>
.ACE1Pro {
  .banner {
    padding: 53px;
    height: 1000px;
    background: url('../../../assets/ACE 1 Pro页面配图/首页配图.png') no-repeat
      center;
    background-size: cover;
    .title {
      line-height: 86.88px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 60px;
      font-weight: 700;
    }
    .osDMS {
      margin-top: 28px;
      padding: 0px 228px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 363.12px;
        height: 73.4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .video {
    height: 1080px;
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
      object-position: 50% 60%;
    }
  }
  .display {
    background: rgba(0, 0, 0, 1);
    position: relative;
    padding-top: 61px;
    height: 1080px;
    .top {
      text-align: center;
      .title {
        font-size: 48px;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
      }
      .text {
        font-size: 36px;
        font-weight: 500;
        color: rgba(0, 92, 255, 1);
      }
    }
    .img {
      margin-top: 70px;
      height: 675px;
      border-radius: 20px;
      border: 2px solid rgba(128, 128, 128, 1);
      width: 100%;
      img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
    .left {
      position: absolute;
      top: 954px;
      left: 489px;
      width: fit-content;
      text-align: center;
      .title {
        margin-bottom: 19px;
        font-size: 36px;
        height: 46px;
        font-weight: 700;
        color: rgba(0, 92, 255, 1);
      }
      .text {
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        line-height: 30px;
      }
    }
    .right {
      position: absolute;
      top: 954px;
      left: 1089px;
      width: fit-content;
      text-align: center;
      .title {
        margin-bottom: 19px;
        height: 46px;
        font-size: 36px;
        font-weight: 700;
        color: rgba(0, 92, 255, 1);
      }
      .text {
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
  .anodisedAuminium {
    background: rgba(0, 0, 0, 1);
    height: 1080px;
    .title {
      padding: 115px 0px 95px 0px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-size: 48px;
      font-weight: 700;
    }
    .img {
      border-radius: 20px;
      border: 2px solid rgba(128, 128, 128, 1);
      width: 100%;
      height: 675px;
      img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .High-speed {
    background: rgba(0, 0, 0, 1);
    height: 1080px;
    .title {
      padding: 115px 0px 95px 0px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-size: 48px;
      font-weight: 700;
    }
    .img {
      border-radius: 20px;
      border: 2px solid rgba(128, 128, 128, 1);
      width: 100%;
      height: 675px;
      img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .product {
    position: relative;
    height: 1080px;
    background: url('../../../assets/ACE 1 Pro页面配图/第五页爆炸图.png')
      no-repeat center;
    background-size: cover;
    .line {
      width: 885px;
      height: 744px;
      position: absolute;
      top: 94px;
      left: 499px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      color: rgba(255, 255, 255, 1);
      font-size: 20px;
      height: 46px;
      line-height: 35px;
    }
    .topLeft {
      position: absolute;
      top: 56px;
      left: 421px;
    }
    .topRight {
      position: absolute;
      top: 56px;
      right: 425px;
    }
    .rightCon {
      position: absolute;
      top: 607px;
      right: 427px;
    }
    .bottomRight {
      text-align: end;
      position: absolute;
      top: 847px;
      right: 427px;
    }
    .bottomLeft {
      position: absolute;
      top: 847px;
      left: 421px;
    }
  }
  .ACE1ProCon {
    background: rgba(0, 0, 0, 1);
    padding: 103px 11px 0px;
    .titleBox {
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 36px;
      padding-bottom: 23px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 95px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      margin-top: 21px;
      padding: 0px 10px;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 5px;
      }
      .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 459px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 400px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemD {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 233px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemE {
        position: relative;
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 700px;
        height: 233px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(0, 92, 255, 0.5);
        .text {
          color: rgba(255, 255, 255, 1);
        }
        .rightImg {
          color: rgba(255, 255, 255, 1);
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          top: 10px;
          right: 43px;
          width: 310px;
          .img {
            width: 310px;
            height: 214.2px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .top {
            height: 30px;
            line-height: 30px;
            position: absolute;
            top: 0px;
            right: 0px;
          }
          .left {
            height: 30px;
            line-height: 30px;
            position: absolute;
            top: 91px;
          }
          .right {
            width: fit-content;
            height: 30px;
            line-height: 30px;
            position: absolute;
            top: 165px;
            left: 267px;
          }
        }
      }
      .one {
        display: flex;
        justify-content: space-between;
      }
      .two {
        display: flex;
        justify-content: space-between;
        .left {
          width: 700px;
          display: flex;
          justify-content: space-between;
          flex-flow: wrap;
        }
        .right {
          width: 460px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .downloads {
      margin-top: 150px;
      .box {
        padding: 0px 10px;
        display: flex;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        .itemA {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
        .itemB {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 251px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
      }
    }
  }
}
</style>
